/*** IMPORTS FROM imports-loader ***/
var define = false;

import gsap from "gsap";
export default class Filters{

    constructor(element) {
        this.elWrapper = element;
        this.elSelects = this.elWrapper.querySelectorAll('select');
        this.elContentWrapper = document.querySelector('.js-filters-content');
        this.paged = 1;

        this.bindEvents();
    }

    bindEvents(){
        [...this.elSelects].map((el,n) => {
            el.addEventListener('change', this.updateViews.bind(this));
        });

        document.addEventListener('click', (e)=>{
            if(e.target.classList.contains('js-filters-pagination')){
                e.preventDefault();
                this.paged = e.target.getAttribute('data-index');
                this.updateViews();
            }
        })
    }

    updateViews(){
        this.elWrapper.classList.add('is-loading');
        let formValues = new FormData(this.elWrapper);
        let currItems = this.elContentWrapper.querySelectorAll('.js-filters-item');
        let currPagination  = this.elContentWrapper.querySelectorAll('.js-filters-pagination-wrapper');

        formValues = this.parseURL(formValues);

        gsap.to(currItems, {y:'15px', alpha:0, duration:0.5});
        gsap.to(currPagination, {y:'15px', alpha:0, duration:0.5});


        fetch(`/ajax/properties${formValues}/${this.paged}/`, {
            method: 'GET',
            headers: {
                'Content-type': 'text/plain',
                'X-Requested-With':'XMLHttpRequest'
            }
        }).then((response) =>  {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response);
        }).then((data) => {
            this.elContentWrapper.innerHTML = data;
            this.elWrapper.classList.remove('is-loading');

            let currItems = this.elContentWrapper.querySelectorAll('.js-filters-item');
            let currPagination  = this.elContentWrapper.querySelectorAll('.js-filters-pagination-wrapper');
            gsap.from(currItems, {y:'15px', alpha:0, duration:0.5});
            gsap.from(currPagination, {y:'15px', alpha:0, duration:0.5});

        }).catch((error) => {
            console.warn(error);
            this.elWrapper.classList.remove('is-loading');
        });
    }

    parseURL(formValues){
        let url = '';
        for (var key of formValues.keys()) {
            let value = formValues.get(key);
            if(value){
                url +='/'+value;
            }
        }
        return encodeURI(url);
    }

}
