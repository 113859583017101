/*** IMPORTS FROM imports-loader ***/
var define = false;

export default class Menu{

	constructor() {
		this.elHeader = document.querySelector('.js-header');
		this.elToggler = document.querySelector('.js-mobile-menu-toggler');
		this.elSearchToggler = document.querySelector('.js-header-search-toggler')
		this.elMobileMenu = document.querySelector('.js-mobile-menu');
		this.elMobileMenuCollapses = this.elMobileMenu.querySelectorAll('.menu-item-has-children');
		this.elSearchBar = document.querySelector('.js-header-search');

		this.bindEvents();

	}

	bindEvents(){
		this.elToggler.addEventListener('click', this.toggleMobileMenu.bind(this));
		[...this.elMobileMenuCollapses].map((el,n) => {
			el.addEventListener('click', this.toggleMobileCollapses.bind(this, el, n));
		});
		this.elSearchToggler.addEventListener('click', this.toggleSearch.bind(this));
	}

	toggleMobileMenu(){
		this.elToggler.classList.toggle('is-open');
		this.elMobileMenu.classList.toggle('is-shown');
	}

	toggleMobileCollapses(el,n,e){
		[...this.elMobileMenuCollapses].map((el,i) => {
			if(n != i){
				el.classList.remove('is-open');
			}
		});
		this.elMobileMenuCollapses[n].classList.toggle('is-open');
	}

	toggleSearch(){
		this.elSearchBar.classList.toggle('is-open');
	}

}
