/*** IMPORTS FROM imports-loader ***/
var define = false;

import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
export default class SubNav{

    constructor(element) {
        this.elWrapper = element;
        this.elTrigger = this.elWrapper.querySelectorAll(".js-sub-nav-trigger");
        this.elBlocks = [];
        this.elHeader = document.querySelector('.js-header');

        this.init();
        this.bindEvents();
    }


    bindEvents(){
        [...this.elTrigger].map((el, n) => {
            el.addEventListener('click', this.scrollTo.bind(this, el, n));
        });
    }

    init(){
        [...this.elTrigger].map((el, n) => {
            let target = el.getAttribute('data-target');
            if(document.querySelector(target)){
                this.elBlocks.push(document.querySelector(target));
            }
        });
        this.elBlocks.forEach((el,n) => {
            ScrollTrigger.create({
                trigger : el,
                start:"top center",
                end:"bottom center",
                onEnter : (obj) => {
                    let id = obj.trigger.getAttribute('id');
                    let currLink = document.querySelectorAll('.js-sub-nav-trigger[data-target^="#'+id+'"]')[0];
                    if(currLink){
                        currLink.classList.add('is-active');
                    }
                },
                onEnterBack : (obj) => {
                    let id = obj.trigger.getAttribute('id');
                    let currLink = document.querySelectorAll('.js-sub-nav-trigger[data-target^="#'+id+'"]')[0];
                    if(currLink){
                        currLink.classList.add('is-active');
                    }
                },
                onLeave: () => {
                    [...this.elTrigger].map((el, n) => {
                        el.classList.remove('is-active');
                    });
                },
                onLeaveBack: () => {
                    [...this.elTrigger].map((el, n) => {
                        el.classList.remove('is-active');
                    });
                },
            });
        })
    }

    scrollTo(el,n,e){
        e.preventDefault();
        let target = el.getAttribute('data-target');
        let elemTarget = document.querySelector(target);
        gsap.to(window, {
            duration: 1,
            scrollTo: {
                y: elemTarget,
                // offsetY:this.elHeader.offsetHeight + this.elWrapper.offsetHeight
            },
            ease:"power2.out"
        });
    }

}
