/*** IMPORTS FROM imports-loader ***/
var define = false;

import Swiper from 'swiper/js/swiper.min';
export default class PropertyDetails{

    constructor() {
        this.elWrapper = document.querySelector('.js-property-details');
        this.elPopin = document.querySelector('.js-property-details-popin');
        this.elPopinClose= document.querySelector('.js-property-details-popin-close');
        this.elSlider = document.querySelector('.js-property-details-popin-slider');
        this.elTrigger = document.querySelector('.js-property-details-trigger');
        this.elBullets = document.querySelector('.js-property-details-popin-slider-bullets');
        this.elArrowLeft = document.querySelector('.js-property-details-popin-slider-bullets-left');
        this.elArrowRight = document.querySelector('.js-property-details-popin-slider-bullets-right');

        this.init();
        this.bindEvents();
    }


    init(){
        this.swiper = new Swiper(this.elSlider, {
            loop:true,
            pagination:{
                el:this.elBullets,
                clickable:true
            },
            navigation: {
                nextEl: this.elArrowRight,
                prevEl: this.elArrowLeft,
            },
        });
    }

    bindEvents(){
        this.elTrigger.addEventListener('click', this.showPopin.bind(this));
        this.elPopinClose.addEventListener('click', this.hidePopin.bind(this));
    }

    showPopin(){
        this.elPopin.classList.add('is-shown');
    }

    hidePopin(){
        this.elPopin.classList.remove('is-shown');
    }
}
