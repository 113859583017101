/*** IMPORTS FROM imports-loader ***/
var define = false;

import YouTubePlayer from "youtube-player";

export default class VideoManager {

    constructor(element) {
        this.elWrapper = element;
        this.elIframeWrapper = this.elWrapper.querySelector('.js-video-wrapper');
        this.elBtn = this.elWrapper.querySelector('.js-video-trigger');
        this.elCover = this.elWrapper.querySelector('.js-video-cover');

        this.init();
        this.bindEvents();
    }

    init(){
        if(this.elBtn){
            this.player = YouTubePlayer(this.elIframeWrapper);
            this.player.loadVideoById(this.elWrapper.getAttribute('data-video-id'));
        }
    }

    bindEvents(){
        if(this.elBtn){
            this.elBtn.addEventListener('click', this.onClickCover.bind(this));
        }
    }

    onClickCover(){
        this.elCover.classList.add('is-hidden');
        this.player.playVideo();
    }

}
