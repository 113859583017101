/*** IMPORTS FROM imports-loader ***/
var define = false;

export default class InputHandler{

    constructor(element) {
        this.elWrapper = element;
        this.elInputs = this.elWrapper.querySelectorAll('input , textarea');
        this.elInput = this.elInputs[0];
        this.bindEvents();
    }

    bindEvents(){
        this.elInput.addEventListener('input', this.updateClass.bind(this));
    }

    updateClass(){
        if(this.elInput.value){
            this.elWrapper.classList.add('has-val');
        }
        else{
            this.elWrapper.classList.remove('has-val');
        }
    }

}
