/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap, ScrollTrigger, Kira, CustomEase) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            html              = document.querySelector('html'),
            body              = document.querySelector('body'),
            $body             = $('body'),
            $pageLoader       = $('.page-loader'),
            scrollAreas       = document.querySelectorAll('.scroll-area'),
            $splitItems       = $body.find('[data-splittext]'),
            $menuWrapper      = $('#mobile-menu'),
            $menuButton       = $('#header .btn-menu')
        ;


        /*
		|
		| Easings
		|----------
        */
        CustomEase.create("easeCustom", "M0,0 C0.4,0 0.2,1 1,1")
        CustomEase.create("easeSmooth", "M0,0 C0.19,1 0.22,1 1,1")
        CustomEase.create("easeCustomPower1", "M0,0 C0.165,0.84 0.44,1 1,1")
        CustomEase.create("easeExpo", "M0, 0 C1, 0 0, 1 1, 1")

        /*
		|
		| SplitText
		|------------
        */
        $.each($splitItems, function() {
            const $item = $(this);
            const type = $item.data('splittext') ? $item.data('splittext') : 'words, chars';
            const split = new SplitText($item, { type: type, linesClass: 'item-line', wordsClass: 'item-word', charsClass: 'item-char' });
            $item.find('.item-line').wrapInner('<div class="item-line-inner"></div>');
            $item[0]['split'] = split;
        });

        $body.on('dom:ready', () => {
            $splitItems.addClass('split-ready')
        } )


        /*
        |
        | Scroll Areas
        |---------------
        */
        Array.prototype.forEach.call(scrollAreas, element => {
            Scrollbar.init(element);
        });


        /*
		|
		| Loader
		|---------
        */
        if (sessionStorage.getItem('loaded_once') === null) {
            sessionStorage.setItem('loaded_once', 'loaded_once');
        }

        if ($pageLoader.hasClass('active')){
            const loaderTl = gsap.timeline({ paused: true, /*onComplete: () => $pageLoader.remove()*/ });

            loaderTl.to($pageLoader.find('.item-loadbar-inner'), 0.4, { scaleX: 1, ease: 'Power0.easeNone' }, 'start')
            loaderTl.to($pageLoader.find('.item-content'), 0.8, { autoAlpha: 0, ease: 'Power1.easeOut' }, '-=0')
            loaderTl.call(() => { app.dispachEvent(body, 'dom:ready'); }, null)
            loaderTl.to($pageLoader, 0.8, { autoAlpha: 0, ease: 'Power1.easeOut' }, '-=0')

            $(window).on('load', function () {
                loaderTl.play();

            });
        } else {
            $(window).on('load', function(){
                app.dispachEvent(body, 'dom:ready');
            })

        }


        /*
        |
		| Kira
		|-----------
        */
        const kira = new Kira({
            loadEvent: [body, 'dom:ready'],
            scrollTrigger: {
                markers: false,
                //scroller: scrollContainerSelector,
            },
            tweenParams: {
                start: '-=0.6'
            }
        });

        /*
        | fadeInUp
        |-----------
        */
        kira.add('fadeInUp', (item, timeline, start) => {
            timeline.fromTo(item, 1.3, { y: 50 }, { y: 0, autoAlpha: 1, ease: 'easeSmooth' }, 'start')
        });

        /*
       | fadeInLeft
       |-----------
       */
        kira.add('fadeInLeft', (item, timeline, start) => {
            timeline.fromTo(item, 1.3, { x: -50 }, { x: 0, autoAlpha: 1, ease: 'easeSmooth' }, 'start')
        });

        /*
        | fadeInUp.parallax.sm
        |-----------------------
        */
        kira.add('fadeInUp.parallax', (item, timeline, start) => {
            timeline.fromTo(item, 0.8, { y: 100 }, { y: 0, autoAlpha: 1, ease: 'Sine.easeOut' }, 'start')
        });

        /*
        | splitline
        |------------
        */
        kira.add('splitline', (item, timeline, start) => {
            const delay = item.dataset.delay ? item.dataset.delay : 0.012;
            item.querySelectorAll('.item-line-inner').forEach( line => {
                timeline.from($(line), 1.35, { y: '101%', ease: 'easeSmooth', delay:delay}, 'start')
            } )
        });

        /*
        | splittext.long
        |-----------------
        */
        kira.add('splittext.long', (item, timeline, start) => {
            const delay = item.dataset.delay ? item.dataset.delay : 0.01;
            timeline.staggerFrom(item.split.chars, 0.05, {y: 15, opacity: 0, ease: 'Sine.easeOut' }, delay, 'start')
        });

        /*
        | fadeInLeft.parallax.sm
        |-------------------------
        */
        kira.add('fadeInLeft.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFromTo($item.querySelectorAll('[data-stagger-item]'), 0.6, { x: 20 }, { autoAlpha: 1, x: 0, ease: 'Power1.easeOut' }, '0.1', 'start')
        });

        /*
        | fadeInUp.parallax.sm
        |-------------------------
        */
        kira.add('fadeInUp.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFromTo($item.querySelectorAll('[data-stagger-item]'), 1, { y: 20 }, { opacity: 1, y: 0, ease: 'Power1.easeOut' }, '0.1', 'start')
        });

        kira.init();
    }
}
