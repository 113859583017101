/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/

require('@lib/iziModal/js/iziModal.js')($); //désolé
import gsap from "gsap";
import CustomEase from "@lib/gsap-pro/CustomEase";
import ScrollTo from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "@lib/gsap-pro/SplitText"

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollTo);
gsap.registerPlugin(SplitText);
/*
|
| Importing Components
|-----------------------
*/
import CookieManager from '@components/cookie-manager';
import Menu from '@components/menu.js';
import PropertyDetails from "./components/property-details";
import SliderProperties from "./components/slider-properties";
import VideoManager from "./components/video-manager";
import CarouselMobile from "./components/carousel-mobile";
import SubNav from "./components/sub-nav";
import InputHandler from "./components/input-handler";
import Filters from "./components/filters";
import SliderBig from "./components/slider-big";
import BannerHome from "./components/banner-home";
import Kira from "./components/kira";

/*
|
| Importing Utils
|------------------- 
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import animations from "./components/animations";
import main from '@pages/main.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
	{
		'file': animations,
		'dependencies': [app, gsap, ScrollTrigger, Kira, CustomEase]
	},
	{
		'file': main, 
		'dependencies': [app, Menu, PropertyDetails, SliderProperties, VideoManager, CarouselMobile, SubNav, InputHandler, Filters, SliderBig, BannerHome]
    },
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

