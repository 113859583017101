/*** IMPORTS FROM imports-loader ***/
var define = false;

import Swiper from 'swiper/js/swiper.min';
export default class SliderBig{

    constructor(element) {
        this.elWrapper = element;
        this.elSlider = this.elWrapper.querySelector('.js-slider-big-swiper');
        this.elArrowLeft = this.elWrapper.querySelector('.js-slider-big-left');
        this.elArrowRight = this.elWrapper.querySelector('.js-slider-big-right');
        this.elScrollBar = this.elWrapper.querySelector('.js-slider-big-scrollbar');

        this.init();
    }

    init(){
        this.swiper = new Swiper(this.elSlider, {
            loop:false,
            slidesPerView: 'auto',
            navigation: {
                nextEl: this.elArrowRight,
                prevEl: this.elArrowLeft,
            },
            scrollbar: {
                el: this.elScrollBar,
                draggable: true,
                hide:false
            },
        });
    }

}
