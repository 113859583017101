/*** IMPORTS FROM imports-loader ***/
var define = false;

import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin  );
export default class BannerHome{

    constructor(element) {
        this.elHeader = document.querySelector('.js-header');
        this.elWrapper = element;
        this.elBtn = this.elWrapper.querySelector('.js-banner-home-scrollto');
        this.elNext = this.elWrapper.nextSibling.nextElementSibling;
        this.bindEvents();
    }

    bindEvents(){
        this.elBtn.addEventListener('click', () => {
            gsap.to(window, {
                duration: 1,
                scrollTo: {
                    y: this.elNext,
                    offsetY:this.elHeader.offsetHeight
                },
                ease:"power2.out"
            });
        })
    }

}
