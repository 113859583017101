/*** IMPORTS FROM imports-loader ***/
var define = false;

import SliderProperties from "../components/slider-properties";

export default {
	init: (app, Menu, PropertyDetails, SliderProperties, VideoManager, CarouselMobile, SubNav, InputHandler, Filters, SliderBig, BannerHome) => {
		/*
		|
		| Constants
		|-----------
		*/
        const 
            $body         = $('body');
		;


        /*
		|
		| Cookie Manager
		|-----------------
		*/

        $body.on('loaderEnd', () => console.log('ended'))


        new Menu();

        if($('.js-property-details').length){
            new PropertyDetails();
        }

        if($('.js-slider-properties').length){
            $('.js-slider-properties').each((n,item) => {
                new SliderProperties(item);
            })
        }

        if($('.js-video').length){
            $('.js-video').each((n,item) => {
                new VideoManager(item);
            })
        }

        if($('.js-carousel-mobile').length){
            $('.js-carousel-mobile').each((n,item) => {
                let startAt = $(item).data('startat') ? $(item).data('startat') : false;
                new CarouselMobile(item, {
                    stickToRight:true,
                    startAt:startAt
                });
            })
        }

        if($('.js-sub-nav').length){
            $('.js-sub-nav').each((n,item) => {
                new SubNav(item);
            })
        }

        if($('.gfield input, .gfield textarea').length){
            $('.gfield input, .gfield textarea').not('[type="checkbox"]').each((n,item) => {
                new InputHandler($(item).parents('.gfield')[0]);
            });
        }

        if($('.js-filters').length){
            $('.js-filters').each((n,item) => {
                new Filters(item);
            })
        }

        if($('.js-slider-big').length){
            $('.js-slider-big').each((n,item) => {
                new SliderBig(item);
            })
        }

        if($('.js-banner-home').length){
            $('.js-banner-home').each((n,item) => {
               new BannerHome(item);
            });
        }
	}
}
