/*** IMPORTS FROM imports-loader ***/
var define = false;

import Swiper from 'swiper/js/swiper.min';
export default class SliderProperties{

    constructor(element) {
        this.elWrapper = element;
        this.elSlider = this.elWrapper.querySelector('.js-slider-properties-swiper');
        this.elArrowLeft = this.elWrapper.querySelector('.js-slider-properties-left');
        this.elArrowRight = this.elWrapper.querySelector('.js-slider-properties-right');

        this.init();
    }

    init(){
        this.swiper = new Swiper(this.elSlider, {
            loop:false,
            slidesPerView: 1,
            spaceBetween: 24,
            navigation: {
                nextEl: this.elArrowRight,
                prevEl: this.elArrowLeft,
            },
            breakpoints : {
                768:{
                    slidesPerView: 2,
                },
                1140:{
                    slidesPerView: 3,
                }
            }
        });
    }

}
